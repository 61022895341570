#page.news.show{
  .category-title{
    max-width: 1240px;
    border-bottom: 1px solid #999;
    font-size: 20px;
    font-weight: 400;
    margin-top: 30px;
  }
  .news-content{
    margin-top: 30px;
    .title{
      font-size: 1.1rem;
      font-weight: bold;
      text-align: center;
    }
    img{
      max-width: 100%;
      margin: 0 auto;
      display: block;
    }

    iframe{
      max-width: 100%;
      margin: 0 auto;
      display: block;
    }

    table {
      border-collapse: collapse;
      max-width: 100%;
      width: 630px;
      margin: 5px auto;

    }


    th{
      background-color: #eeeeee;
    }
    tr{
      border: 1px solid #cccccc;
    }

    max-width: 1240px;
    line-height: 30px;
  }
}

#page.news.list{
  .news-list{
    max-width: 1270px;
    margin-top: 40px;
    .title {
      border-left: 4px solid #ffa100;
      font-size: 20px;
      padding-left: 10px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .image-news{
      img{
      }
      border-bottom: 1px solid #eee;
      margin-bottom: 25px;
    }

    a{
      color: #333333;
    }
  }
  .news{
    margin-top: 30px;
  }
  .title{
    margin-top: 30px;
  }
  .page-number{
    margin-top: 40px;
  }
}